import { EMoosaSarConfigImageSize, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { useComponentsContext } from '@discngine/moosa-shared-components-gen';
import React, { FC, JSXElementConstructor, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { CustomizedCellRendererProps } from '../../CellRendererProps';
import { ISarItemCallbacks, ISarTableItem } from '../../MoosaSarTablePanelProps';
import styles from '../MoosaSarTable.module.less';

import { ItemProps } from './MoosaSarTableItemProps';
import { MoosaSarTableItemValues } from './MoosaSarTableItemValues';

export interface ISarItemProps extends ISarItemCallbacks {
  columns: IMoosaSarConfigTag[][];
  itemData: ISarTableItem;
  coreStructure: string[];
  onPinChange: (rowId: string) => void;
  onPinnedSubstancesOrderChange?: (fromItem: string, toItem: string) => void;
  imageSize: EMoosaSarConfigImageSize;
  itemIndex: number;
  isColorizingTextMode: boolean;
  cellRenderer: JSXElementConstructor<CustomizedCellRendererProps>;
}

const sizeStyleClasses = new Map([
  [EMoosaSarConfigImageSize.Small, styles.small],
  [EMoosaSarConfigImageSize.Medium, styles.medium],
  [EMoosaSarConfigImageSize.Large, styles.large],
  [EMoosaSarConfigImageSize.ExtraLarge, styles.extraLarge],
]);

const Item: FC<ItemProps> = ({
  columns,
  coreStructure,
  imageSize,
  itemData,
  onPinChange,
  provided = {},
  isColorizingTextMode,
  CellRenderer,
  onCellClick,
  onCellHover,
  onMoleculeClick,
  onMoleculeHover,
}) => {
  const {
    Button,
    Icons: { PinFilledIcon, PinIcon, DragIcon },
  } = useComponentsContext();

  const [hoveredColumn, setHoveredColumn] = useState<string>('');

  const handleMoleculeClick = useCallback(() => {
    if (onMoleculeClick) {
      onMoleculeClick(itemData);
    }
  }, [itemData, onMoleculeClick]);

  const handleMoleculeHover = useCallback(() => {
    if (onMoleculeHover) {
      onMoleculeHover(itemData);
    }
  }, [itemData, onMoleculeHover]);

  const handleCellClick = useCallback(() => {
    if (onCellClick) {
      onCellClick(itemData);
    }
  }, [itemData, onCellClick]);

  const handleCellHover = useCallback(() => {
    if (onCellHover) {
      onCellHover(itemData, [hoveredColumn]);
    }
  }, [hoveredColumn, itemData, onCellHover]);

  const handleColumnMouseEnter = (columnId: string) => {
    setHoveredColumn(columnId);
  };

  const handleColumnMouseLeave = () => {
    setHoveredColumn('');
  };

  const headerText = itemData.headerText;

  const onPinIconClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onPinChange(itemData.rowId);
      event.stopPropagation();
    },
    [itemData.rowId, onPinChange]
  );

  const onDragIconClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <div
      className={`${styles.item} ${!itemData ? styles.emptyItem : ''} ${
        itemData?.isSelected && styles.selectedItem
      }`}
      onClick={handleCellClick}
      onMouseEnter={handleCellHover}
    >
      {itemData && (
        <>
          <div className={styles.itemHeader}>
            <div className={styles.itemHeaderButtons}>
              <Button
                className={`${styles.pinButton} ${
                  itemData.isPinned ? styles.pinned : ''
                }`}
                icon={itemData.isPinned ? <PinFilledIcon /> : <PinIcon />}
                size="small"
                type="text"
                onClick={onPinIconClick}
              />
              {itemData.isPinned && (
                <div
                  className={styles.dragIcon}
                  {...(provided.dragHandleProps ?? {})}
                  onClick={onDragIconClick}
                >
                  <DragIcon />
                </div>
              )}
            </div>
            <div className={styles.id}>{headerText}</div>
          </div>
          <CellRenderer
            alignSmiles={coreStructure}
            className={sizeStyleClasses.get(imageSize)}
            coreSmiles={coreStructure}
            sarItemData={itemData}
            onMoleculeClick={handleMoleculeClick}
            onMoleculeHover={handleMoleculeHover}
          />
          <MoosaSarTableItemValues
            columns={columns}
            handleColumnMouseEnter={handleColumnMouseEnter}
            handleColumnMouseLeave={handleColumnMouseLeave}
            isColorizingTextMode={isColorizingTextMode}
            itemData={itemData}
          />
        </>
      )}
    </div>
  );
};

export const MoosaSarTableItem: FC<ISarItemProps> = ({
  itemData,
  onPinChange,
  coreStructure,
  imageSize,
  columns,
  itemIndex,
  isColorizingTextMode,
  cellRenderer,
  onMoleculeHover,
  onMoleculeClick,
  onCellHover,
  onCellClick,
}) => {
  return (
    <>
      {itemData && itemData.isPinned && (
        <Draggable draggableId={itemData.rowId} index={itemIndex}>
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                className={styles.itemWrapper}
                {...provided.draggableProps}
              >
                <Item
                  CellRenderer={cellRenderer}
                  columns={columns}
                  coreStructure={coreStructure}
                  imageSize={imageSize}
                  isColorizingTextMode={isColorizingTextMode}
                  itemData={itemData}
                  provided={provided}
                  onCellClick={onCellClick}
                  onCellHover={onCellHover}
                  onMoleculeClick={onMoleculeClick}
                  onMoleculeHover={onMoleculeHover}
                  onPinChange={onPinChange}
                />
              </div>
            );
          }}
        </Draggable>
      )}
      {itemData && !itemData.isPinned && (
        <Item
          CellRenderer={cellRenderer}
          columns={columns}
          coreStructure={coreStructure}
          imageSize={imageSize}
          isColorizingTextMode={isColorizingTextMode}
          itemData={itemData}
          onCellClick={onCellClick}
          onCellHover={onCellHover}
          onMoleculeClick={onMoleculeClick}
          onMoleculeHover={onMoleculeHover}
          onPinChange={onPinChange}
        />
      )}
    </>
  );
};
