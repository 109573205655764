import { classNames } from '@discngine/moosa-common';
import { Dropdown } from 'antd';
import React, { FC, useCallback } from 'react';

import { ColorPickerPanelPure } from '../ColorPickerPanelPure/ColorPickerPanelPure';
import { ColorView } from '../ColorView/ColorView';
import { ColorViewStub } from '../ColorViewStub/ColorViewStub';

import styles from './ColorPickerPure.module.less';

type ColorPickerPureProps = {
  color?: string;
  onColorChange?: (color: string) => void;
  isDisabled?: boolean;
};

export const ColorPickerPure: FC<ColorPickerPureProps> = React.memo(
  function ColorPickerPure({ color, onColorChange, isDisabled }) {
    const picker = useCallback(
      () => (
        <div className={styles.colorPanel}>
          <ColorPickerPanelPure color={color} onColorChange={onColorChange} />
        </div>
      ),
      [color, onColorChange]
    );

    return (
      <Dropdown disabled={isDisabled} dropdownRender={picker} trigger={['click']}>
        <div
          className={classNames(styles.colorView, {
            [styles.active]: !!color,
          })}
        >
          {color && <ColorView color={color} />}
          {!color && <ColorViewStub />}
        </div>
      </Dropdown>
    );
  }
);
