import { MoosaDataContext } from '@discngine/moosa-models';
import { MoosaTemplateSelectorWidgetContextWrapper } from '@discngine/moosa-template-selector-widget';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import './index.less';

import App from './App';
import { dataService } from './dataService';
import { store } from './store/store';

const dngTheme = {
  token: {
    colorPrimary: '#004D67',
    colorSuccess: '#3A9E86',
    colorWarning: '#EB7B00',
    colorError: '#DF4948',
    colorInfo: '#017DA5',
    sizeStep: 4,
    borderRadius: 2,
    wireframe: false,
  },
};
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <MoosaDataContext.Provider value={dataService}>
    <Provider store={store}>
      <MoosaTemplateSelectorWidgetContextWrapper dataService={dataService}>
        <HashRouter>
          <ConfigProvider theme={dngTheme}>
            <App />
          </ConfigProvider>
        </HashRouter>
      </MoosaTemplateSelectorWidgetContextWrapper>
    </Provider>
  </MoosaDataContext.Provider>
);

// import * as serviceWorker from './serviceWorker.js';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
