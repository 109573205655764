import ResizeObserver from 'rc-resize-observer';
import { FC, useMemo, useState, useCallback } from 'react';

import { MoosaSarTable } from './MoosaSarTable/MoosaSarTable';
import { ISarTableItem, MoosaSarTablePanelProps } from './MoosaSarTablePanelProps';

export const MoosaSarTablePanelGeneric: FC<MoosaSarTablePanelProps> = (props) => {
  const [width, setWidth] = useState(0);
  const pinnedSubstances = props.pinnedItems.map((item) => item.rowId);

  // todo: optimize rendering
  const sarItems = useMemo(() => {
    const pinnedItems: ISarTableItem[] = props.pinnedItems.map((item) => {
      return {
        ...item,
        isPinned: true,
      };
    });
    // todo: optimize rendering
    const notPinnedItems = props.items.map((item) => {
      return {
        ...item,
        isPinned: false,
      };
    });

    const ordered = [...pinnedItems, ...notPinnedItems];

    return ordered;
  }, [props.items, props.pinnedItems]);

  const onPinChange = useCallback(
    (rowId: string) => {
      let newPinnedSubstances: string[] = [...pinnedSubstances];

      if (pinnedSubstances.includes(rowId)) {
        const indexOfPinnedSubstances = newPinnedSubstances.indexOf(rowId);

        newPinnedSubstances.splice(indexOfPinnedSubstances, 1);
      } else {
        newPinnedSubstances = [...pinnedSubstances, rowId];
      }

      props.onPinnedSubstancesChange(newPinnedSubstances);
    },
    [pinnedSubstances, props]
  );

  const onPinnedSubstancesOrderChange = useCallback(
    (fromItem: string, toItem: string) => {
      const newPinnedSubstancesOrder: string[] = [...pinnedSubstances];

      const fromIndex = newPinnedSubstancesOrder.indexOf(fromItem);
      const toIndex = newPinnedSubstancesOrder.indexOf(toItem);
      const [removed] = newPinnedSubstancesOrder.splice(fromIndex, 1);

      newPinnedSubstancesOrder.splice(toIndex, 0, removed);

      props.onPinnedSubstancesChange(newPinnedSubstancesOrder);
    },
    [pinnedSubstances, props]
  );

  return (
    <ResizeObserver onResize={({ width }) => setWidth(width)}>
      <MoosaSarTable
        cellRenderer={props.cellRenderer}
        columnLabelMap={props.columnLabelMap}
        columns={props.columns}
        coreStructure={props.coreStructures}
        imageSize={props.imageSize}
        isColorizingTextMode={props.isColorizingTextMode}
        pinnedIds={pinnedSubstances}
        sarItems={sarItems}
        width={width}
        onCellClick={props.onCellClick}
        onCellHover={props.onCellHover}
        onLabelClick={props.onLabelClick}
        onLabelHover={props.onLabelHover}
        onMoleculeClick={props.onMoleculeClick}
        onMoleculeHover={props.onMoleculeHover}
        onOpenSarConfigPanel={props.onOpenSarConfigPanel}
        onPinChange={onPinChange}
        onPinnedSubstancesOrderChange={onPinnedSubstancesOrderChange}
      />
    </ResizeObserver>
  );
};
