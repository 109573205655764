import { SAR_IMAGE_SIZE_OPTIONS } from '@discngine/moosa-common';
import { EMoosaSarConfigImageSize } from '@discngine/moosa-models';
import {
  RadioChangeEvent,
  useComponentsContext,
} from '@discngine/moosa-shared-components-gen';
import React from 'react';

import styles from './MoosaSarTableConfig.module.less';

export interface IMoosaSarConfigImageSizeProps {
  currentSize: EMoosaSarConfigImageSize;
  onChange: (value: EMoosaSarConfigImageSize) => void;
}

export const MoosaSarConfigImageSizeGeneric: React.FC<IMoosaSarConfigImageSizeProps> = ({
  currentSize,
  onChange,
}) => {
  const { Radio } = useComponentsContext();

  const changeHandle = ({
    target: { value },
  }: RadioChangeEvent<EMoosaSarConfigImageSize>) => {
    if (value === undefined) return;

    onChange(value);
  };

  return (
    <div className={styles.imageSize}>
      <Radio
        options={Object.values(SAR_IMAGE_SIZE_OPTIONS)}
        value={currentSize}
        onChange={changeHandle}
      />
    </div>
  );
};
