import { type InputHTMLAttributes, JSXElementConstructor, ReactNode, Ref } from 'react';
import * as React from 'react';

import { ValueInputProps, ValueInputSizeType } from '../ValueInput/ValueInputProps';

export interface IComponentsContext {
  Input: JSXElementConstructor<InputProps>;
  ValueInput: JSXElementConstructor<ValueInputProps>;
  Select: JSXElementConstructor<SelectProps>;
  Tooltip: JSXElementConstructor<TooltipProps>;
  Popover: JSXElementConstructor<PopoverProps>;
  Button: JSXElementConstructor<ButtonProps>;
  Row: JSXElementConstructor<RowProps>;
  Col: JSXElementConstructor<ColProps>;
  Modal: JSXElementConstructor<ModalProps>;
  Dropdown: JSXElementConstructor<DropdownProps>;
  Menu: JSXElementConstructor<MenuProps>;
  MenuItem: JSXElementConstructor<MenuItemProps>;
  Empty: JSXElementConstructor<EmptyProps>;
  Radio: JSXElementConstructor<RadioProps>;
  Upload: JSXElementConstructor<UploadProps>;
  message: MessageProps;
  Icons: { [key in ComponentContextIconsEnum]: JSXElementConstructor<IconProps> };
}

export interface InputProps {
  addonAfter?: ReactNode;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: ValueInputSizeType;
  type?: 'number' | 'text';
  value: InputHTMLAttributes<HTMLInputElement>['value'];
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur'];
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
}

export interface SelectProps {
  className?: string;
  children?: ReactNode;
}

// from AntD
export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

export interface TooltipProps {
  title?: string;
  children?: ReactNode;
  placement?: TooltipPlacement;
}

export type ActionType = 'hover' | 'focus' | 'click' | 'contextMenu';

export interface PopoverProps {
  children?: ReactNode;
  open?: boolean;
  placement?: TooltipPlacement;
  onOpenChange?: (open: boolean) => void;
  trigger?: ActionType;
  content?: ReactNode;
  zIndex?: number;
}

export interface ButtonProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  onClick?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
  icon?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  type?: 'primary' | 'default' | 'text';
}

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: Ref<HTMLDivElement>;
  gutter?: number | [number, number];
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  wrap?: boolean;
  align?: 'top' | 'middle' | 'bottom' | 'stretch';
}
export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: Ref<HTMLDivElement>;
  span?: number | string;
}

export interface ModalProps {
  className?: string;
  children?: ReactNode;
  centered?: boolean;
  footer?: ReactNode;
  closeIcon?: ReactNode;
  open?: boolean;
  width?: number;
  onOk?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
  onCancel?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
}

export enum ComponentContextIconsEnum {
  DeleteIcon = 'DeleteIcon',
  CheckIcon = 'CheckIcon',
  DownIcon = 'DownIcon',
  CopyIcon = 'CopyIcon',
  EllipsisIcon = 'EllipsisIcon',
  PinFilledIcon = 'PinFilledIcon',
  PinIcon = 'PinIcon',
  DragIcon = 'DragIcon',
  DownloadIcon = 'DownloadIcon',
  UploadIcon = 'UploadIcon',
}

export interface IconProps {
  className?: string;
  onClick?: InputHTMLAttributes<SVGSVGElement | HTMLSpanElement>['onClick'];
}

// Dropdown
export interface DropdownProps {
  disabled?: boolean;
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
  children: ReactNode;
}

// Menu
export interface MenuProps {
  selectable?: boolean | undefined;
  children: ReactNode;
}
export interface MenuItemProps {
  disabled?: boolean | undefined;
  onClick: () => void;
  children: ReactNode;
}

export interface EmptyProps {
  description?: React.ReactNode;
  image?: React.ReactNode;
  imageStyle?: React.CSSProperties | undefined;
  children: ReactNode;
}

// Radio
interface CheckboxOptionType<T extends any> {
  label: React.ReactNode;
  value: T;
  disabled?: boolean;
  title?: string;
}
export interface RadioChangeEvent<T extends any> {
  target: { value?: T };
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: Event;
}
export interface RadioProps<T extends any = any> {
  value?: T;
  options?: (string | number | CheckboxOptionType<T>)[] | undefined;
  onChange?: ((e: RadioChangeEvent<T>) => void) | undefined;
}

// Upload
type BeforeUploadValueType = void | boolean | string | Blob | File;
export interface UploadProps {
  name?: string | undefined;
  accept?: string | undefined;
  showUploadList?: boolean | undefined;
  beforeUpload?:
    | ((
        file: File,
        fileList: File[]
      ) => BeforeUploadValueType | Promise<BeforeUploadValueType>)
    | undefined;
}

// message
export type TypeOpen = (
  content: React.ReactNode,
  duration?: number | VoidFunction,
  onClose?: VoidFunction
) => PromiseLike<boolean>;
export interface MessageProps {
  info: TypeOpen;
  success: TypeOpen;
  error: TypeOpen;
  warning: TypeOpen;
  loading: TypeOpen;
  destroy(key?: React.Key): void;
}
