import { useComponentsContext } from '@discngine/moosa-shared-components-gen';
import { FC, useMemo, ReactElement } from 'react';

import { ISarItem } from '../MoosaSarTablePanelProps';

import styles from './MenuRenderer.module.less';

export interface MenuItem {
  label: string;
  icon: ReactElement;
  isEnabled: boolean;
  onClick: (moleculeId: string) => void;
}

interface MenuRendererProps {
  menu: (moleculeId: string) => MenuItem[] | MenuItem[];
  sarItemData: ISarItem;
}

export const MenuRenderer: FC<MenuRendererProps> = ({ menu, sarItemData }) => {
  const {
    Dropdown,
    Menu,
    MenuItem,
    Icons: { EllipsisIcon },
  } = useComponentsContext();

  const menuItems = useMemo(() => {
    if (Array.isArray(menu)) {
      return menu;
    }

    return menu(sarItemData.rowId);
  }, [menu, sarItemData.rowId]);

  return (
    <div className={styles.menuRenderer}>
      <Dropdown
        dropdownRender={() => (
          <Menu selectable={false}>
            {menuItems.map((item) => (
              <MenuItem
                key={item.label}
                disabled={!item.isEnabled}
                onClick={() => item.onClick(sarItemData.rowId)}
              >
                {item.icon} {item.label}
              </MenuItem>
            ))}
          </Menu>
        )}
        trigger={['click']}
      >
        <EllipsisIcon className={styles.menuBtn} />
      </Dropdown>
    </div>
  );
};

export interface MenuOverlayProps {
  sarItemData: ISarItem;
  menu: (moleculeId: string) => MenuItem[] | MenuItem[];
}

export const MenuOverlay: FC<MenuOverlayProps> = ({ sarItemData, menu }) => {
  return (
    <div className={styles.menuOverlay}>
      <MenuRenderer menu={menu} sarItemData={sarItemData} />
    </div>
  );
};
