import {
  ColumnId,
  DatasetRowId,
  DecisionTreeGroupedResult,
  DecisionTreePropertyNode,
  DTNodeId,
  GraphNodeData,
  PortGroupingType,
} from '@discngine/moosa-models';
import * as go from 'gojs';

import { NODE_WIDTH } from '../../../constants';
import {
  CommonNodeTemplateProps,
  DiagramData,
  DiagramHistogramView,
  DiagramNodeData,
  DiagramNodePosition,
  DTNodeOutputPortType,
} from '../../../types';
import { getColumnLabel, getFilteredGroups } from '../../../utils';
import {
  DANGER,
  EMPTY_DATA_TEXT,
  getNodeBackgroundColor,
  getNodeBorderColor,
  getNodeBorderSize,
  NODE_BORDER,
  NODE_FILL,
  NODE_HEADER_FILL,
  NODE_HEADER_TEXT,
  NODE_MENU,
  OUTPUT_MISSING,
  OUTPUT_NO,
  OUTPUT_YES,
  WHITE,
} from '../colors';
import { findObjectsByName, MAX_LABEL_LENGTH, trimHeaderLabel } from '../utils';

import {
  getNodeHeaderColor,
  getNodeHeaderTextColor,
  InputPort,
  OutputPort,
} from './common';

const filteredGroupsBadge = (options?: Partial<go.Panel>) =>
  new go.Panel('Auto', {
    alignment: go.Spot.RightCenter,
    width: 24,
    height: 24,
    ...options,
    toolTip: new go.Adornment('Spot', { background: 'transparent' })
      .add(new go.Placeholder({ padding: 5 }))
      .add(
        new go.Panel('Auto', {
          alignment: go.Spot.Bottom,
          alignmentFocus: go.Spot.Top,
        })
          .add(
            new go.Shape('RoundedRectangle', {
              fill: 'rgba(0,0,0,0.75)',
              strokeWidth: 0,
              parameter1: 4,
            })
          )
          .add(
            new go.TextBlock({
              stroke: WHITE,
              font: '400 14px Roboto, sans-serif',
              spacingAbove: 4,
              spacingBelow: 4,
              margin: new go.Margin(6, 8),
            }).bind(
              new go.Binding(
                'text',
                'groups',
                (groups: DecisionTreeGroupedResult, object: go.GraphObject) => {
                  if (!object.part) return;

                  const nodeData: GraphNodeData = object.part.data;
                  const nodeGroups = groups[nodeData.key];

                  if (!nodeGroups) return '';

                  const bullet = '•';

                  return `These groups were filtered out:\n${bullet} ${getFilteredGroups(
                    nodeGroups
                  ).join(`\n${bullet} `)}`;
                }
              ).ofModel()
            )
          )
      ),
  })
    .bind(
      new go.Binding(
        'visible',
        'groups',
        (groups: DecisionTreeGroupedResult, object: go.GraphObject) => {
          if (!object.part) return;

          const nodeData: GraphNodeData = object.part.data;
          const nodeGroups = groups[nodeData.key];

          if (!nodeGroups) return false;

          return getFilteredGroups(nodeGroups).length > 0;
        }
      ).ofModel()
    )
    .add(new go.Shape('Circle', { strokeWidth: 0, fill: DANGER }))
    .add(
      new go.Panel('Horizontal', { margin: new go.Margin(2, 0, 0, 0) })
        .add(
          new go.Shape({
            width: 10,
            height: 10,
            fill: WHITE,
            strokeWidth: 0,
            margin: new go.Margin(-7, 1, 0, 0),
            geometry: go.Geometry.parse(
              'M7.56988 8.37499C7.79754 8.17578 7.79754 7.82162 7.56988 7.62241L4.82917 5.22429C4.50588 4.94141 3.99992 5.171 3.99992 5.60058V7.14766L0.999902 7.14766C0.53046 7.14766 0.149902 7.52821 0.149902 7.99766C0.149902 8.4671 0.53046 8.84766 0.999902 8.84766H3.99992V10.3968C3.99992 10.8264 4.50588 11.056 4.82917 10.7731L7.56988 8.37499Z',
              true
            ),
          })
        )
        .add(new go.TextBlock('0', { stroke: WHITE }))
    );
const nodeMenuButton = (options?: Partial<go.Panel>) => {
  return new go.Panel('Auto', {
    name: 'ItemMenu',
    background: 'transparent',
    alignment: go.Spot.Right,
    cursor: 'pointer',
    padding: new go.Margin(4, 2, 4, 2),
    click: (event, node) => {
      event.diagram.commandHandler.showContextMenu(node);
    },
    ...options,
  }).add(
    new go.TextBlock({
      margin: new go.Margin(0, -3, 0, 3),
      text: '...',
      angle: 90,
      stroke: NODE_MENU,
      font: '600 14px Roboto, sans-serif',
    })
  );
};

export const propertyNodeHeader = (options?: Partial<go.Panel>) =>
  new go.Panel('Auto', { ...options })
    .add(
      new go.Shape('RoundedTopRectangle', {
        parameter1: 8,
        strokeWidth: 0,
        fill: NODE_HEADER_FILL,
      })
        .bind(
          new go.Binding('fill', 'condition', (_, object) => getNodeHeaderColor(object))
        )
        .bind(
          new go.Binding('fill', 'structure', (_, object) => getNodeHeaderColor(object))
        )
        .bind(
          new go.Binding('fill', 'editableNodeId', (_, object) =>
            getNodeHeaderColor(object)
          ).ofModel()
        )
    )
    .add(
      new go.Panel('Horizontal', {
        margin: new go.Margin(6, 8),
        alignment: go.Spot.Left,
      })
        .add(
          new go.Shape('CapsuleV', {
            fill: 'transparent',
            stroke: EMPTY_DATA_TEXT,
            strokeWidth: 1,
            width: 7,
            height: 16,
          })
        )
        .add(
          new go.TextBlock({
            stroke: NODE_HEADER_TEXT,
            font: '400 14px Roboto, sans-serif',
            margin: new go.Margin(0, 0, -1.5, 8),
            maxSize: new go.Size(280, NaN),
            wrap: go.TextBlock.WrapFit,
            toolTip: new go.Adornment(go.Panel.Auto)
              .add(
                new go.Shape({ fill: '#FFFFFF' }),
                new go.TextBlock({
                  margin: 10,
                  maxSize: new go.Size(300, NaN),
                  wrap: go.TextBlock.WrapFit,
                }).bind(
                  'text',
                  'text',
                  (text: DiagramNodeData['text'], object: go.GraphObject) => {
                    if (!text) return;

                    const modelData = object.diagram?.model.modelData as DiagramData;

                    return getColumnLabel(text, modelData?.columnLabelMap);
                  }
                )
              )
              .bind(
                'visible',
                'text',
                (text: DiagramNodeData['text'], object: go.GraphObject) => {
                  if (!text) return false;

                  const modelData = object.diagram?.model.modelData as DiagramData;

                  if (modelData.histogramView === DiagramHistogramView.Hide) {
                    return false;
                  }

                  const label = getColumnLabel(text, modelData?.columnLabelMap);

                  return label.length > MAX_LABEL_LENGTH;
                }
              ),
          })
            .bind(
              'text',
              'text',
              (text: DiagramNodeData['text'], object: go.GraphObject) => {
                const modelData = object.diagram?.model.modelData as DiagramData;
                const label = getColumnLabel(text, modelData?.columnLabelMap);

                if (modelData.histogramView === DiagramHistogramView.Hide) {
                  return label;
                } else {
                  return trimHeaderLabel(label);
                }
              }
            )
            .bind('stroke', 'condition', (_, object: go.GraphObject) =>
              getNodeHeaderTextColor(object)
            )
            .bind('stroke', 'structure', (_, object: go.GraphObject) =>
              getNodeHeaderTextColor(object)
            )
        )
    )
    .add(
      filteredGroupsBadge({
        margin: new go.Margin(0, 8, 2, 0),
      })
    )
    .add(
      nodeMenuButton({
        margin: new go.Margin(0, 3, 1, 0),
      }).bind(new go.Binding('visible', 'contextMenu', Boolean).ofObject())
    );

interface Shape {
  color: string;
  geometry: go.Geometry;
  width?: number;
  height?: number;
  margin?: go.Margin;
}

const shapes: Record<keyof DecisionTreePropertyNode['outputArrows'], Shape> = {
  missingValues: {
    color: OUTPUT_MISSING,
    geometry: go.Geometry.parse(
      'M764 280.9c-14-30.6-33.9-58.1-59.3-81.6C653.1 151.4 584.6 125 512 125s-141.1 26.4-192.7 74.2c-25.4 23.6-45.3 51-59.3 81.7-14.6 32-22 65.9-22 100.9v27c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-27c0-99.5 88.6-180.4 197.6-180.4s197.6 80.9 197.6 180.4c0 40.8-14.5 79.2-42 111.2-27.2 31.7-65.6 54.4-108.1 64-24.3 5.5-46.2 19.2-61.7 38.8a110.85 110.85 0 00-23.9 68.6v31.4c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-31.4c0-15.7 10.9-29.5 26-32.9 58.4-13.2 111.4-44.7 149.3-88.7 19.1-22.3 34-47.1 44.3-74 10.7-27.9 16.1-57.2 16.1-87 0-35-7.4-69-22-100.9zM512 787c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56z',
      true
    ),
    width: 13,
    height: 15,
    margin: new go.Margin(4, 6, 5, 3),
  },
  yes: {
    color: OUTPUT_YES,
    geometry: go.Geometry.parse(
      'M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z',
      true
    ),
    margin: new go.Margin(2, 7, 8, 1),
  },
  no: {
    color: OUTPUT_NO,
    geometry: go.Geometry.parse(
      'M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z',
      true
    ),
    margin: new go.Margin(2, 6, 7, 0),
  },
};
const getShapeByKey = (key: keyof DecisionTreePropertyNode['outputArrows']): go.Panel => {
  return new go.Panel('Auto', { margin: new go.Margin(3, 0, 1, 0) })
    .add(
      new go.Shape('Circle', {
        stroke: shapes[key].color,
        strokeWidth: 2,
        fill: WHITE,
        margin: 3,
      })
    )
    .add(
      new go.Shape({
        margin: shapes[key].margin || new go.Margin(0, 6, 7, 0),
        width: shapes[key].width || 14,
        height: shapes[key].height || 14,
        fill: shapes[key].color,
        strokeWidth: 0,
        geometry: shapes[key].geometry,
      })
    );
};
const propertyNodeOutput = (
  key: Exclude<DTNodeOutputPortType, 'combine'>,
  options?: Partial<go.Panel>,
  onClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void
) => {
  // Port icon
  const icon = getShapeByKey(key);
  const missingValuesIcon = getShapeByKey('missingValues').bind(
    'visible',
    'portGroupingType',
    (portGroupingType: PortGroupingType) => {
      return (
        (key === 'yes' && portGroupingType === PortGroupingType.TrueMissing) ||
        (key === 'no' && portGroupingType === PortGroupingType.FalseMissing)
      );
    }
  );

  const icons = new go.Panel('Horizontal');

  switch (key) {
    case 'yes':
      icons.add(missingValuesIcon).add(icon);
      break;

    case 'no':
      icons.add(icon).add(missingValuesIcon);
      break;

    case 'missingValues':
      icons.add(icon);
  }

  return OutputPort({
    portId: key,
    icons,
    options: { ...options },
    onClick,
  });
};

export const propertyNodeOutputs = ({
  onClick,
  options,
}: {
  onClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void;
  options?: Partial<go.Panel>;
}) =>
  new go.Panel('Vertical', {
    defaultStretch: go.GraphObject.Horizontal,
    stretch: go.GraphObject.Horizontal,
    width: 280,
    ...options,
  }).add(
    new go.Panel('Table')
      .add(propertyNodeOutput('no', { row: 0, column: 0, width: 75 }, onClick))
      .add(
        propertyNodeOutput(
          'missingValues',
          { row: 0, column: 1, width: 75 },
          onClick
        ).bind('visible', 'portGroupingType', (portGroupingType: PortGroupingType) => {
          return portGroupingType === PortGroupingType.Regular;
        })
      )
      .add(propertyNodeOutput('yes', { row: 0, column: 2, width: 75 }, onClick))
  );

export interface IPropertyLikeNodeFrameProps extends CommonNodeTemplateProps {
  onEditConditionClick?: (data: DiagramNodeData) => void;
  showMenu?: (a: go.GraphObject, b: go.Diagram, c: go.Tool) => void;
  hideMenu?: () => void;
  bodyPanel: (panel: go.Panel) => go.Panel;
}

export const propertyLikeNodeFrame = ({
  onEditConditionClick,
  onResultsClick,
  showMenu,
  hideMenu,
  bodyPanel,
}: IPropertyLikeNodeFrameProps) => {
  return (
    new go.Node('Vertical', {
      cursor: 'move',
      locationSpot: new go.Spot(0.5, 0, 0, 15),
      defaultStretch: go.GraphObject.Horizontal,
      width: NODE_WIDTH,
      portSpreading: go.Node.SpreadingPacked,
      click: (event, object: go.GraphObject) => {
        if ((findObjectsByName(event, 'ItemMenu')?.size || 0) > 0) return;

        onEditConditionClick?.(object.part?.data as DiagramNodeData);
      },
      contextClick: (event) => {
        // Prevent context menu by right mouse button
        event.handled = true;
      },
      contextMenu: showMenu
        ? go.GraphObject.make(go.HTMLInfo, {
            show: showMenu,
            hide: hideMenu,
          })
        : null,
    })
      // Prevent movement for grouped nodes
      .bind('movable', 'group', (group: DTNodeId) => !group)
      .bind('selectable', 'group', (group: DTNodeId) => !group)

      // Node position
      .bind(
        new go.Binding(
          'location',
          'position',
          ({ x, y }: DiagramNodePosition): go.Point => new go.Point(x, y),
          ({ x, y }: go.Point): DiagramNodePosition => ({ x, y })
        )
      )

      // Input port
      .add(InputPort('input'))

      .add(
        new go.Panel('Vertical', {
          name: 'container',
          defaultStretch: go.GraphObject.Horizontal,
        })
          // Node header
          .add(propertyNodeHeader({ alignment: go.Spot.TopCenter }))

          // Node content
          .add(
            new go.Panel('Table', { defaultStretch: go.GraphObject.Fill })
              .add(
                new go.Panel('Auto', {
                  row: 0,
                  column: 0,
                  stretch: go.GraphObject.Vertical,
                }).add(
                  new go.Shape({
                    fill: NODE_BORDER,
                    width: 1,
                    height: 20,
                    strokeWidth: 0,
                  })
                    .bind(
                      new go.Binding(
                        'fill',
                        'highlightedNode',
                        getNodeBorderColor
                      ).ofModel()
                    )
                    .bind(
                      new go.Binding(
                        'width',
                        'highlightedNode',
                        getNodeBorderSize
                      ).ofModel()
                    )
                )
              )
              .add(
                new go.Panel('Auto', {
                  row: 0,
                  column: 2,
                  stretch: go.GraphObject.Vertical,
                }).add(
                  new go.Shape({
                    fill: NODE_BORDER,
                    width: 1,
                    height: 20,
                    strokeWidth: 0,
                  })
                    .bind(
                      new go.Binding(
                        'fill',
                        'highlightedNode',
                        getNodeBorderColor
                      ).ofModel()
                    )
                    .bind(
                      new go.Binding(
                        'width',
                        'highlightedNode',
                        getNodeBorderSize
                      ).ofModel()
                    )
                )
              )
              .add(
                bodyPanel(
                  new go.Panel('Vertical', {
                    row: 0,
                    column: 1,
                    background: NODE_FILL,
                  }).bind(
                    new go.Binding(
                      'background',
                      'highlightedNode',
                      getNodeBackgroundColor
                    ).ofModel()
                  )
                )
              )
              .add(
                new go.Shape('RoundedBottomRectangle', {
                  row: 2,
                  column: 0,
                  columnSpan: 3,
                  parameter1: 8,
                  fill: NODE_FILL,
                  stroke: NODE_BORDER,
                  strokeWidth: 1,
                  height: 8,
                  margin: new go.Margin(-2, 0, 0, 0),
                })
                  .bind(
                    new go.Binding(
                      'fill',
                      'highlightedNode',
                      getNodeBackgroundColor
                    ).ofModel()
                  )
                  .bind(
                    new go.Binding(
                      'stroke',
                      'highlightedNode',
                      getNodeBorderColor
                    ).ofModel()
                  )
                  .bind(
                    new go.Binding(
                      'strokeWidth',
                      'highlightedNode',
                      getNodeBorderSize
                    ).ofModel()
                  )
              )
          )
      )

      // Output ports
      .add(propertyNodeOutputs({ onClick: onResultsClick }))
  );
};
