import { SAR_IMAGE_SIZE_OPTIONS } from '@discngine/moosa-common';
import { useComponentsContext } from '@discngine/moosa-shared-components-gen';
import { FC, useCallback, useMemo } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { ISarTableItem } from '../MoosaSarTablePanelProps';

import styles from './MoosaSarTable.module.less';
import { MoosaSarTableProps } from './MoosaSarTableProps';
import { MoosaSarTableRow } from './SarTableRow/MoosaSarTableRow';

const LABELS_PANEL_WIDTH = 240;

export const MoosaSarTable: FC<MoosaSarTableProps> = (props) => {
  const { Button, Empty } = useComponentsContext();

  const itemsPerRow = useMemo(() => {
    if (props.width !== 0) {
      let result = Math.floor(
        (props.width - LABELS_PANEL_WIDTH) / SAR_IMAGE_SIZE_OPTIONS[props.imageSize].width
      );

      if (result < 1) result = 1;

      return result;
    }

    return 4;
  }, [props.imageSize, props.width]);

  const numberOfRows = Math.ceil(props.sarItems.length / itemsPerRow);

  const getRowItems = useCallback(
    (offset: number) => {
      const rowItems: ISarTableItem[] = [];

      [...Array(itemsPerRow)].forEach((value, index) => {
        rowItems.push(props.sarItems[index + offset]);
      });

      return rowItems;
    },
    [itemsPerRow, props.sarItems]
  );

  const getRowIdByIndices = useCallback(
    (rowIndex: number, itemIndex: number) => {
      return props.sarItems[rowIndex * itemsPerRow + itemIndex].rowId;
    },
    [itemsPerRow, props.sarItems]
  );

  const dragEndHandler = useCallback(
    (result: DropResult) => {
      if (result.reason === 'CANCEL' || !result.destination) {
        return;
      }

      if (
        result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index
      ) {
        return;
      }

      if (!props.onPinnedSubstancesOrderChange) {
        return;
      }

      const source = getRowIdByIndices(
        parseInt(result.source.droppableId),
        result.source.index
      );

      const destination = getRowIdByIndices(
        parseInt(result.destination.droppableId),
        result.destination.index
      );

      props.onPinnedSubstancesOrderChange(source, destination);
    },
    [getRowIdByIndices, props]
  );

  return (
    <DragDropContext onDragEnd={dragEndHandler}>
      <div className={styles.root} id="MoosaSarTable">
        {props.columns.length === 0 && (
          <div className={styles.emptyNotificationBlock}>
            <Empty
              description={
                <span className={styles.notification}>No parameters selected</span>
              }
              imageStyle={{ height: 40 }}
            >
              <Button
                className={styles.addPropertiesBtn}
                type="text"
                onClick={props.onOpenSarConfigPanel}
              >
                Add parameters
              </Button>
            </Empty>
          </div>
        )}
        {[...Array(numberOfRows)].map((value, index) => {
          const rowItems = getRowItems(index * itemsPerRow);

          return (
            <MoosaSarTableRow
              key={index}
              cellRenderer={props.cellRenderer}
              columnLabelMap={props.columnLabelMap}
              columns={props.columns}
              coreStructure={props.coreStructure}
              imageSize={props.imageSize}
              isColorizingTextMode={props.isColorizingTextMode}
              itemsPerRow={itemsPerRow}
              rowIndex={index}
              rowItems={rowItems}
              onCellClick={props.onCellClick}
              onCellHover={props.onCellHover}
              onLabelClick={props.onLabelClick}
              onLabelHover={props.onLabelHover}
              onMoleculeClick={props.onMoleculeClick}
              onMoleculeHover={props.onMoleculeHover}
              onPinChange={props.onPinChange}
            />
          );
        })}
      </div>
    </DragDropContext>
  );
};
