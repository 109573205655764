import {
  IMoosaSarConfigCoreProps,
  IMoosaSarConfigImageSizeProps,
  IMoosaSarConfigImportExportProps,
  MoosaSarConfigCoreGeneric,
  MoosaSarConfigImageSizeGeneric,
  MoosaSarConfigImportExportGeneric,
  MoosaSarTablePanelGeneric,
  MoosaSarTablePanelProps,
} from '@discngine/moosa-sar-table-gen';
import { AntComponentsProvider } from '@discngine/moosa-shared-components';

export const MoosaSarTablePanel = (props: MoosaSarTablePanelProps) => (
  <AntComponentsProvider>
    <MoosaSarTablePanelGeneric {...props} />
  </AntComponentsProvider>
);

// TODO: remove after MoosaSarTableConfig refactoring
export const MoosaSarConfigCore = (props: IMoosaSarConfigCoreProps) => (
  <AntComponentsProvider>
    <MoosaSarConfigCoreGeneric {...props} />
  </AntComponentsProvider>
);

export const MoosaSarConfigImageSize = (props: IMoosaSarConfigImageSizeProps) => (
  <AntComponentsProvider>
    <MoosaSarConfigImageSizeGeneric {...props} />
  </AntComponentsProvider>
);

export const MoosaSarConfigImportExport = (props: IMoosaSarConfigImportExportProps) => (
  <AntComponentsProvider>
    <MoosaSarConfigImportExportGeneric {...props} />
  </AntComponentsProvider>
);
// TODO: remove after MoosaSarTableConfig refactoring
