import { Expression } from 'estree';

export enum ExpressionType {
  Identifier = 'Identifier',
  BinaryExpression = 'BinaryExpression',
  CallExpression = 'CallExpression',
  Literal = 'Literal',
  LogicalExpression = 'LogicalExpression',
  UnaryExpression = 'UnaryExpression',
}

export type {
  Expression,
  Identifier,
  Literal,
  BinaryExpression,
  UnaryExpression,
  LogicalExpression,
  CallExpression,
  PrivateIdentifier
} from 'estree';

export interface Formula {
  expression: Expression;
}
